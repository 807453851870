<template>
  <CRow class="reachability">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-table class="data_table"
               ref="departmentTable" 
               :data="departmentData" 
               :striped="true" 
               :hoverable="true" 
               :mobile-cards="true" 
               :paginated="isPaginated" 
               :per-page="perPage" 
               :current-page.sync="currentPage" 
               :pagination-simple="isPaginationSimple" 
               :pagination-position="paginationPosition"
               detailed 
               detail-key="department_name" 
               :show-detail-icon="true"             
               :default-sort-direction="'desc'"
               :sort-icon="sortIcon"
               :sort-icon-size="sortIconSize"
               default-sort="views">

        <template slot-scope="props">
          <b-table-column field="department_name" sortable :label="$t('common.Department')" :searchable="searchEnabled" width="40%">
            <span>{{props.row.department_name}}</span>
          </b-table-column>
          <b-table-column field="views" sortable :label="$t('common.Total_views')" width="15%">
            <div class="d-flex align-items-center count">
              <span>{{props.row.views}}</span>
            </div>
          </b-table-column> 
          <b-table-column field="reach" sortable :label="$t('common.Unique_views')" width="15%">
            <div class="d-flex align-items-center count">
              <span>{{props.row.reach}}</span>
            </div>
          </b-table-column>                    
          <b-table-column field="likes" sortable label="likes" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('👍')" class="th_image"></span>
            </template>
            <span>{{props.row.likes}}</span>
          </b-table-column>
          <b-table-column field="dislikes" sortable label="dislikes" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('👎')" class="th_image"></span>
            </template>
            <span>{{props.row.dislikes}}</span>
          </b-table-column>
          <b-table-column field="waves" sortable label="waves" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('👏')" class="th_image"></span>
            </template>
            <span>{{props.row.waves}}</span>
          </b-table-column>
          <b-table-column field="hearts" sortable label="hearts" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('💖')" class="th_image"></span>
            </template>
            <span>{{props.row.hearts}}</span>
          </b-table-column>
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{$t('common.No_department_views')}}</span>
          </div>
        </template>
        <template slot="detail" slot-scope="props">
          <b-table class="data_table" 
                    :data="Object.values(props.row.teams)" 
                    :striped="true" 
                    :hoverable="true" 
                    :mobile-cards="true" 
                    :paginated="isPaginated" 
                    :per-page="perPage" 
                    :current-page.sync="currentPage" 
                    :pagination-simple="isPaginationSimple" 
                    :pagination-position="paginationPosition"
                    default-sort-direction='desc'
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    default-sort="views">
            
            <template slot-scope="props">                        
              <b-table-column field="team_name" sortable :label="$t('common.Team')" :searchable="searchEnabled" width="40%">
                <span>{{props.row.team_name}}</span>
              </b-table-column>
              <b-table-column field="views" sortable :label="$t('common.Total_views')" width="15%">
                <div class="d-flex align-items-center count">
                  <span>{{props.row.views}}</span>
                </div>
              </b-table-column> 
              <b-table-column field="reach" sortable :label="$t('common.Unique_views')" width="15%">
                <div class="d-flex align-items-center count">
                  <span>{{props.row.reach}}</span>
                </div>
              </b-table-column>                    
              <b-table-column field="likes" sortable label="likes" centered>
                <template v-slot:header="{}">
                  <span v-html="getTwemoji('👍')" class="th_image"></span>
                </template>
                <span>{{props.row.likes}}</span>
              </b-table-column>
              <b-table-column field="dislikes" sortable label="dislikes" centered>
                <template v-slot:header="{}">
                  <span v-html="getTwemoji('👎')" class="th_image"></span>
                </template>
                <span>{{props.row.dislikes}}</span>
              </b-table-column>
              <b-table-column field="waves" sortable label="waves" centered>
                <template v-slot:header="{}">
                  <span v-html="getTwemoji('👏')" class="th_image"></span>
                </template>
                <span>{{props.row.waves}}</span>
              </b-table-column>
              <b-table-column field="hearts" sortable label="hearts" centered>
                <template v-slot:header="{}">
                  <span v-html="getTwemoji('💖')" class="th_image"></span>
                </template>
                <span>{{props.row.hearts}}</span>
              </b-table-column>                                              
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <span>{{$t('insights.No_employees_reached')}}</span>
              </div>                
            </template>                
          </b-table>                      
        </template>        
      </b-table>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'ItemViewsDepTable',
  props: ['departments'],
  data() {
    return {
      departmentData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',      
      searchEnabled: false
    }
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },
  mounted:function() {
		// Set the prop as the table data
		this.departmentData = Object.values(this.departments);
    // Check if the pagination should be activated
    (this.departmentData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;    
  }
}
</script>
