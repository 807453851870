<template>
  <div v-if="platformPermissionsLoaded && checkPermission('knowledge.insights.item')">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Item_insights')}} {{item.item_title}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow class="insights_filters">
      <CCol cols="2" xl="2" lg="3" md="4" sm="12">
        <b-datepicker v-model="dateFrom"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getItemInsightsData();
                              getItemInsightsChartData();
                              getItemInsightsDepartmentData();"
                      class="d-inline-block align-middle">
          <CButton color="primary"
                    @click="dateFrom = new Date();
                            getItemInsightsData();
                            getItemInsightsChartData();
                            getItemInsightsDepartmentData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol cols="2" xl="2" lg="3" md="4" sm="12">
        <b-datepicker v-model="dateTo"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getItemInsightsData();
                              getItemInsightsChartData();
                              getItemInsightsDepartmentData();"
                      class="d-inline-block align-middle">
          <CButton color="primary"
                    @click="dateTo = new Date();
                            getItemInsightsData();
                            getItemInsightsChartData();
                            getItemInsightsDepartmentData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
      <CCol cols="6" xl="6" lg="6" md="6" sm="12">
        <CCard v-if="loadingData" class="mb-0 h-100">
          <CCardHeader class="pb-0">
            {{$t('insights.Knowledge_item')}} 
          </CCardHeader>
          <CCardBody class="text-center">
            <div class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
          </CCardBody>
        </CCard>

        <knowledgeItem v-else :itemData="item" :navigatable="false" :editable="false" :preview="false" :showLikes="false" :scrollableContent="true"/>
      </CCol>      

      <CCol cols="6" xl="6" lg="6" md="6" sm="12">
        <CRow class="h-100">
          <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
            <CCard class="h-100 mb-0">
              <CCardHeader>
                <CRow>
                  <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                    {{$t('insights.Views')}}
                  </CCol>       
                </CRow>       
              </CCardHeader>
              <CCardBody class="pt-0">
                <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
                  <loadingSpinner mode="auto" :content="null"/>
                </div>
                <Count v-else mode="single_number" :count="itemInsightsData.statistics[0].views" :countTitle="null" :countIcon="null"/>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
            <CCard class="h-100 mb-0">
              <CCardHeader>
                <CRow>
                  <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                    {{$t('insights.Reach')}}
                  </CCol>       
                </CRow>       
              </CCardHeader>
              <CCardBody class="pt-0">
                <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
                  <loadingSpinner mode="auto" :content="null"/>
                </div>
                <Count v-else mode="single_number" :count="itemInsightsData.statistics[0].reach" :countTitle="null" :countIcon="null"/>
              </CCardBody>
            </CCard>        
          </CCol>

          <CCol cols="12" xl="12" lg="12" md="12" sm="12">
            <CCard class="h-100 mb-0">
              <CCardHeader class="pb-0">
                <CRow>
                  <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                    {{$t('common.Sentiment')}}
                  </CCol>       
                </CRow>       
              </CCardHeader>
              <CCardBody>
                <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
                  <loadingSpinner mode="auto" :content="null"/>
                </div>
                <LikesGiven v-else :likesCountPerType="itemInsightsData.likes_count_per_type" mode="inline"/>
              </CCardBody>
            </CCard>        
          </CCol>          

          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
            <CCard class="h-100 mb-0">
              <CCardHeader class="pb-0">
                <CRow>
                  <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                    {{$t('insights.Trend')}}
                  </CCol>       
                </CRow>       
              </CCardHeader>
              <CCardBody class="pt-0">
                <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
                  <loadingSpinner mode="auto" :content="null"/>
                </div>
                <LineChart v-else :lineChartSeries="lineChartSeries" xAxisType="datetime" yAxisType="int" :companyPrimaryColor="companyPrimaryColor"/>
              </CCardBody>
            </CCard>        
          </CCol>          
        </CRow>
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Views_departments')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsDepartmentData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <ItemViewsDepTable v-else :departments="itemInsightsDepartmenData"/>            
          </CCardBody>
        </CCard>        
      </CCol>
    </CRow>   
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import knowledgeItem from '@/components/knowledge/knowledgeItem.vue';

import Count from '@/components/insights/common/Count.vue';
import LineChart from '@/components/insights/common/LineChart.vue';
import LikesGiven from '@/components/insights/common/LikesGiven.vue';
import ItemViewsDepTable from '@/components/insights/knowledge/ItemViewsDepTable.vue';

export default {
  name: 'Item',
  components: {
    loadingSpinner,
    noPermission,
    knowledgeItem,
    Count,
    LineChart,
    LikesGiven,
    ItemViewsDepTable
  },
  watch: { 
    '$route.params.id': {
      handler() {
        // Set the default for dateFrom to today minus 90 days
        this.dateFrom.setDate(this.dateFrom.getDate() -90);
        // Get the itemIdExternal from the route params
        this.itemIdExternal = this.$route.params.id;
        // Get the knowlege item
        this.getItemData();
        // Get the insights data
        this.getItemInsightsData();
        // Get the insights chart data
        this.getItemInsightsChartData();
        // Get the insights department data
        this.getItemInsightsDepartmentData();
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingData: false,
      loadingInsightsData: false,
      loadingInsightsChartData: false,
      loadingInsightsDepartmentData: false,
      itemIdExternal: null,
      item: {},
      itemInsightsData: {},
      itemInsightsChartData: {},
      itemInsightsDepartmenData: {},
      showDownloadButtons: false,
      printingInsights: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      companyPrimaryColor: null,
      lineChartSeries: [],
    }
  },
  methods: {
    getItemData() {
      // Start the loader
      this.loadingData = true;
      // Get the knowledge item
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/item/' + this.itemIdExternal)      
      .then(res => {
        this.item = res.data.data;
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingData = false;
          // Update the showDownloadButtons value
          // this.showDownloadButtons = true;                 
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });       
    },
    getItemInsightsData() {
      // Start the loader
      this.loadingInsightsData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");    
      // Get the topics insights data
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/insights/item/' + this.itemIdExternal + '/overview/' + dateFromAPI + '/' + dateToAPI)      
      .then(res => {
        // Set the itemInsightsData
        this.itemInsightsData = res.data.data;
        // Set default statistics when none are available
        if(this.itemInsightsData.statistics.length === 0) this.itemInsightsData.statistics = [{ views: 0, reach: 0 }];        
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingInsightsData = false;
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    getItemInsightsChartData() {
      // Start the loader
      this.loadingInsightsChartData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");    
      // Get the topics insights data
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/insights/item/' + this.itemIdExternal + '/chart/' + dateFromAPI + '/' + dateToAPI)      
      .then(res => {
        // Set the itemInsightsChartData
        this.itemInsightsChartData = res.data.data;
        // Update the lineChartSeries
        this.lineChartSeries = [{ name: "Views", data: this.itemInsightsChartData }];
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingInsightsChartData = false;
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getItemInsightsDepartmentData() {
      // Start the loader
      this.loadingInsightsDepartmentData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Get the topics insights data
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/insights/item/' + this.itemIdExternal + '/departments/overview/' + dateFromAPI + '/' + dateToAPI)      
      .then(res => {
        // Set the itemInsightsDepartmenData
        this.itemInsightsDepartmenData = res.data.data;
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingInsightsDepartmentData = false;
        }.bind(this), 2000)                         
      })          
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");

      let exportSubject = this.item.kb_intent_category_name + '-' + this.item.kb_intent_subcategory_name + '-' + this.item.item_title;
      exportSubject = exportSubject.replace(/\s/g, '');
      exportSubject = exportSubject.toLowerCase();      

      link.setAttribute("download", "knowledge_insights_" + exportSubject + "_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    exportData(outputData, outputFormat) {
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });

      let exportSubject = this.item.kb_intent_category_name + '-' + this.item.kb_intent_subcategory_name + '-' + this.item.item_title;
      exportSubject = exportSubject.replace(/\s/g, '');
      exportSubject = exportSubject.toLowerCase();       
      
      let exportFileName = 'knowledge-insight-' + exportSubject;      

      if (!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.URL.createObjectURL(new Blob([outputData]));  
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.URL.createObjectURL(new Blob([outputData]));
        }
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute based on the output format
        if(outputFormat === 'csv') {          
          link.setAttribute('download', exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          link.setAttribute('download', exportFileName + '.json');
        }
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
        }
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
  }
}
</script>